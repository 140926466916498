/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { Container, Box, Grid, Typography, Stack, Button } from '@mui/material';
import classNames from 'classnames';

import PageTitleWrapper from 'components/PageTitleWrapper';
import ExpiryWarning from 'components/ExpiryWarning';
import Header from 'components/Header/Header';
import { getSupportTicketList } from 'store/support/actions';
import LoadingSpinner from 'components/LoadingSpinner';

import TicketCard from './TicketCard';
import './styles.scss';

const STATUS = {
  ALL: 'all',
  OPEN: 'open',
  CLOSED: 'closed',
};

const CustomerSupport = ({ getSupportTicketList }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { cusID } = useParams();
  const { currentCustomer: customer } = useSelector((state) => state.customers);
  const { tickets } = useSelector((state) => state.support);
  const [loading, setLoading] = useState(false);
  const [statusFilter, setStatusFiler] = useState(STATUS.OPEN);

  const getTickets = useCallback(async () => {
    try {
      setLoading(true);
      await getSupportTicketList(cusID, statusFilter);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [getSupportTicketList, cusID, statusFilter]);

  useEffect(() => {
    getTickets();
  }, [getTickets, statusFilter]);

  return (
    <>
      <Helmet>
        <title>Support - {customer.name} - Acreto</title>
      </Helmet>
      <div className="wrapper organizations-wrapper">
        <ExpiryWarning />
        <Header />
        <PageTitleWrapper size="lg">
          <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" alignItems="start">
            <Box
              display="flex"
              alignItems={{ xs: 'stretch', md: 'center' }}
              flexDirection={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
              mb={1}
            >
              <Box>
                <Typography variant="h4" component="h4" sx={{ pb: 1 }}>
                  {customer.name}
                </Typography>
                <Typography color="text.secondary" fontSize={20} fontWeight="normal">
                  Your issues and their priorities according to the category.
                </Typography>
              </Box>
            </Box>
            <Stack
              direction={{ xs: 'row', md: 'column' }}
              alignItems={{ xs: 'center', md: 'end' }}
              justifyContent="space-between"
              spacing={1}
              sx={{ width: { xs: '100%', md: 'unset' } }}
            >
              <Button
                variant="contained"
                size="large"
                sx={{ width: 'fit-content' }}
                onClick={() => history.push(`${pathname}/new`)}
                data-testid="button_new-ticket"
              >
                + New Ticket
              </Button>
              <Box className="status-filter">
                <Box
                  className={classNames('filter-option', { active: statusFilter === STATUS.OPEN })}
                  onClick={() => setStatusFiler(STATUS.OPEN)}
                  data-testid="filter-open"
                >
                  Open
                </Box>
                <Box
                  className={classNames('filter-option', { active: statusFilter === STATUS.ALL })}
                  onClick={() => setStatusFiler(STATUS.ALL)}
                  data-testid="filter-all"
                >
                  All
                </Box>
                <Box
                  className={classNames('filter-option', { active: statusFilter === STATUS.CLOSED })}
                  onClick={() => setStatusFiler(STATUS.CLOSED)}
                  data-testid="filter-closed"
                >
                  Closed
                </Box>
              </Box>
            </Stack>
          </Stack>
        </PageTitleWrapper>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Container maxWidth="lg" sx={{ pb: 4 }}>
            <Grid container rowSpacing={3}>
              {tickets.map((ticket) => (
                <Grid item xs={12} key={ticket.id}>
                  <TicketCard ticket={ticket} cusID={cusID} />
                </Grid>
              ))}
            </Grid>
          </Container>
        )}
      </div>
    </>
  );
};

CustomerSupport.propTypes = {
  getSupportTicketList: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  getSupportTicketList: (customerUUID, status) => dispatch(getSupportTicketList({ customerUUID, status })),
});

export default connect(null, mapDispatchToProps)(CustomerSupport);
