import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Accordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails,
  Box,
  Button,
  IconButton,
  Popover,
  Tooltip,
  Typography,
  Divider,
} from '@mui/material';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useSnackbar } from 'notistack';

import useCopyToClipboard from 'hooks/useCopyToClipboard';
import Field from 'components/Field';
import { GATEWAY_TYPE } from 'enum/gateway';
import Translator from 'utils/enumTranslator';
import { regeneratePSK } from 'views/Objects/scenario-actions';

const hiddenToken = '********************************';

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowRightIcon sx={{ fontSize: 32, color: '#4986c5' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : '#fff',
  border: 'none',
  flexDirection: 'row-reverse',
  padding: 0,
  minHeight: 32,
  '&.Mui-expanded': {
    minHeight: 32,
    margin: 0,
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
    '&.Mui-expanded': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
}));

const VPNParameters = ({ data, psk, regeneratePSK }) => {
  const [isExpanded, setExpanded] = useState(false);
  const [pskShow, setPSKShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { copy: handleCopy } = useCopyToClipboard();

  const model = useMemo(() => Translator.model(data.model), [data]);

  const handleRegeneratePSK = async (event) => {
    event.preventDefault();
    setAnchorEl(null);
    await regeneratePSK(data.uuid);
  };

  const openPSKGeneratePop = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closePSKGeneratePop = () => {
    setAnchorEl(null);
  };

  const handleCopyPSK = (event) => {
    if (!psk) {
      openPSKGeneratePop(event);
      return;
    }
    handleCopy(psk);
    enqueueSnackbar('Copied!', {
      variant: 'success',
      autoHideDuration: 1500,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
  };
  const handlePSKShowClick = (event) => {
    if (!psk && !pskShow) {
      openPSKGeneratePop(event);
      return;
    }
    setPSKShow((prev) => !prev);
  };

  return (
    <div className="gateway__detail-vpn flex-one">
      <div>
        <Field.Group label="Model">
          <Field.Text text={model.label} />
        </Field.Group>
        <Accordion
          expanded={isExpanded}
          onChange={() => setExpanded((prev) => !prev)}
          sx={{ boxShadow: 'none', '&:before': { display: 'none' } }}
        >
          <AccordionSummary className="vpn_title">
            <Typography sx={{ fontSize: 16, color: '#4986c5' }}>VPN Parameters</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, pl: 3 }}>
            <Field.Group extraClass="psk-info">
              <div className="psk-info__options">
                <span className="psk-label">Pre-Shared Key: </span>
                <div className="psk-data">
                  <input className="psk-text" disabled value={pskShow ? psk : hiddenToken} />
                  <Divider orientation="vertical" sx={{ ml: 2, mr: 1, height: 20, borderColor: '#abb1b2' }} />
                  <div className="psk-actions">
                    <IconButton size="small" onClick={handleCopyPSK} data-testid="btn-psk_copy">
                      <ContentCopyIcon sx={{ color: '#abb1b2' }} />
                    </IconButton>
                    <Divider orientation="vertical" sx={{ mx: 1, height: 20, borderColor: '#abb1b2' }} />
                    <IconButton size="small" onClick={handlePSKShowClick} data-testid="btn-psk_visible">
                      {pskShow ? (
                        <VisibilityOffOutlinedIcon sx={{ color: '#abb1b2' }} />
                      ) : (
                        <VisibilityOutlinedIcon sx={{ color: '#abb1b2' }} />
                      )}
                    </IconButton>
                    <Popover
                      id="popover-psk"
                      open={!!anchorEl}
                      anchorEl={anchorEl}
                      onClose={closePSKGeneratePop}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Box className="popover-psk">
                        <p>This will invalidate current Pre-Shared Key and generate new one.</p>
                        <p>Are you sure?</p>
                        <div className="generate_buttons">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleRegeneratePSK}
                            data-testid="generate-psk"
                          >
                            Yes
                          </Button>
                          <Button variant="outlined" onClick={closePSKGeneratePop} data-testid="cancel-generate">
                            No
                          </Button>
                        </div>
                      </Box>
                    </Popover>
                  </div>
                </div>
              </div>
            </Field.Group>
            <Field.Group label="Recommended ciphers">
              <Field.Text testId="text-cipher_IKE" text="1 - IKE: aes256gcm16-sha256-ecp256" />
              <Field.Text testId="text-cipher_ESP" text="2 - ESP: aes256gcm16-sha256-ecp256" />
            </Field.Group>
            <Field.Group label="Gateway Address">
              <Field.Text testId="text-server_address" text={data.server_address} />
            </Field.Group>
            <Field.Group label="Peer ID">
              <Field.Text testId="text-peer_id" text={data.peer_id} />
            </Field.Group>
          </AccordionDetails>
        </Accordion>
      </div>

      {data.ipsec_fail_open && (
        <label className="checkbox-label wedge-checkbox-container">
          <input type="checkbox" defaultChecked disabled />
          <span className="checkmark" />
          <p className="check_title">
            {data.gateway_type === GATEWAY_TYPE.IPSEC ? 'IPSec' : 'vGateway'} Fail Open
            <Tooltip
              title={
                <Typography sx={{ fontSize: 12 }}>
                  Default: Disabled
                  <br />
                  When the tunnel is down Fail Open allows accessing the Internet directly using the default gateway,
                  bypassing Acreto
                </Typography>
              }
            >
              <IconButton sx={{ p: 0, pl: 1 }}>
                <HelpCenterOutlinedIcon />
              </IconButton>
            </Tooltip>
          </p>
        </label>
      )}
    </div>
  );
};

VPNParameters.propTypes = {
  data: PropTypes.object,
  psk: PropTypes.string,
  regeneratePSK: PropTypes.func,
};

const mapStateToProps = (state) => ({
  psk: state.objects.psk,
});

const mapDispatchToProps = (dispatch) => ({
  regeneratePSK: (gatewayUUID) => dispatch(regeneratePSK(gatewayUUID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VPNParameters);
