import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Panel, Collapse } from 'react-bootstrap';
import { IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';

import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { REMOTE_DEVICES } from '../../enum/thing';

import './styles.scss';

const getTlsVpnLink = (token) => `${process.env.REACT_APP_API_URL}/v2/tlsvpn/client/ubuntu-18.04?_token=${token}`;
const getOpenVpnLink = (token) => `${process.env.REACT_APP_API_URL}/v2/tlsvpn/config/openvpn-udp?_token=${token}`;

const ThingDevices = ({ data, token: thingToken }) => {
  const [searchText, setSearchText] = useState('');
  const [isCollapsed, setIsCollapsed] = useState({
    'Mobile Devices': false,
    'Personal Computers and Servers': false,
    'Acreto Connect Client Installer': false,
  });

  const { copy } = useCopyToClipboard();

  const vpnLink = useMemo(
    () => ({
      tlsvpn: getTlsVpnLink(thingToken),
      openvpn: getOpenVpnLink(thingToken),
    }),
    [thingToken],
  );

  const handleSearchChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    setSearchText(value);
    setIsCollapsed(
      ...Object.keys(isCollapsed).reduce((acc, key) => {
        acc[key] = !!value;
        return acc;
      }, {}),
    );
  };

  const handleExpand = (title) => (event) => {
    event.preventDefault();
    setIsCollapsed((prev) => ({ ...prev, [title]: !prev[title] }));
  };

  const handleDownload = (vpn) => {
    const link = document.createElement('a');
    link.download = data.name;
    link.href = vpnLink[vpn];
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const searchOptions = (label) => !searchText || label.toLowerCase().includes(searchText.toLowerCase());

  return (
    <div className="panel__devices">
      <Panel>
        <Panel.Heading>
          <input placeholder="Search" onChange={handleSearchChange} />
          <SearchIcon className="search-icon" />
        </Panel.Heading>
        <Panel.Body>
          {REMOTE_DEVICES.map(({ title, options }) => (
            <div key={`key-${title}`} className="download-list">
              <span className="download-list__label" onClick={handleExpand(title)}>
                {isCollapsed[title] ? <ExpandMoreIcon /> : <ExpandMoreIcon sx={{ transform: 'rotate(-90deg)' }} />}
                {title}
              </span>
              <Collapse in={isCollapsed[title]}>
                <div className="download-list__collapse">
                  {options.map(({ label, value }) => {
                    if (!searchOptions(label)) return null;
                    return (
                      <div key={`thing-config-${label}`} className="download__option">
                        <span>{label}</span>
                        <div className="download__functions">
                          <Tooltip componentsProps={{ tooltip: { sx: { fontSize: 12 } } }} title="Download">
                            <IconButton size="small" onClick={() => handleDownload(value)}>
                              <DownloadIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip componentsProps={{ tooltip: { sx: { fontSize: 12 } } }} title="Copy URL">
                            <IconButton size="small" onClick={() => copy(vpnLink[value])}>
                              <ContentCopyIcon id="copy-icon" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Collapse>
            </div>
          ))}
        </Panel.Body>
      </Panel>
    </div>
  );
};

ThingDevices.propTypes = {
  token: PropTypes.string,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const ecosystemId = state.ecosystems.currentEcosystem.uuid;
  const { token } = state.objects[ecosystemId].objects.find((obj) => obj.uuid === ownProps.data.uuid);

  return {
    token,
  };
};

export default connect(mapStateToProps)(ThingDevices);
