import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Divider, Typography, Grid, CircularProgress } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import get from 'lodash/get';

import GraphIcon from 'assets/img/SVG/GraphIcon.svg';
import ThreatChip from 'components/ThreatChip';
import { getThreatIndex } from 'utils/utils';
import './styles.scss';

const ThreatIndex = () => {
  const { threatIndex: data } = useSelector((state) => state.ecosystems.ecosystem);
  const isLoading = useSelector((state) => get(state.ecosystems.ecosystem.loading, 'index', false));

  const internal = data?.Internal || 0;
  const external = data?.External || 0;
  const combined = internal * 0.7 + external * 0.3;

  return (
    <Box
      sx={{ border: '1px solid #EFF2F7', borderRadius: 1, height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '28px',
            width: '28px',
            backgroundColor: '#FFEED8',
            opacity: 0.8,
            borderRadius: '4px',
          }}
        >
          <img src={GraphIcon} alt="count" />
        </Box>
        <Typography sx={{ fontSize: 16, fontWeight: 600, lineHeight: '20px', color: '#1A2E42', ml: 1 }}>
          Threat Index
        </Typography>
        <HelpIcon fontSize="small" sx={{ ml: 1 }} data-tooltip-id="threat-tooltip" />
        <ReactTooltip id="threat-tooltip" type="info" effect="solid" place="bottom" className="threatTooltip">
          Threat Index is a formulation that reflects the type, severity, perspective (direction) and volume of threats.
        </ReactTooltip>
      </Box>
      <Divider variant="fullWidth" />
      {isLoading ? (
        <Box sx={{ height: 200 }} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Grid container minHeight="160px" height="100%">
          <Grid item xs={4}>
            <Box
              p={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-evenly"
              height="100%"
              borderRight="1px solid #EFF2F7"
            >
              <Typography sx={{ color: '#A3ABB3', fontSize: 14, fontWeight: 500, lineHeight: '16px' }}>
                Overall Threat
              </Typography>
              <ThreatChip
                testId="overall"
                color={getThreatIndex(combined).color}
                text={combined.toFixed(1)}
                textProps={{ fontSize: 32, fontWeight: 800, lineHeight: '32px' }}
                label={getThreatIndex(combined).label}
                spacing={12}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              p={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-evenly"
              height="100%"
              borderRight="1px solid #EFF2F7"
            >
              <Typography
                sx={{ color: '#A3ABB3', fontSize: 14, fontWeight: 500, lineHeight: '16px', textAlign: 'center' }}
              >
                Internal Threat
              </Typography>
              <ThreatChip
                testId="internal"
                color={getThreatIndex(internal).color}
                text={internal.toFixed(1)}
                textProps={{ fontSize: 32, fontWeight: 800, lineHeight: '32px' }}
                label={getThreatIndex(internal).label}
                spacing={12}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              p={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-evenly"
              height="100%"
            >
              <Typography
                sx={{ color: '#A3ABB3', fontSize: 14, fontWeight: 500, lineHeight: '16px', textAlign: 'center' }}
              >
                External Threat
              </Typography>
              <ThreatChip
                testId="external"
                color={getThreatIndex(external).color}
                text={external.toFixed(1)}
                textProps={{ fontSize: 32, fontWeight: 800, lineHeight: '32px' }}
                label={getThreatIndex(external).label}
                spacing={12}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ThreatIndex;
