import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import PropTypes from 'prop-types';

const FileChip = ({ label, onDelete, onDownload }) => (
  <Box
    sx={{
      border: '1px solid #A0DFFD',
      borderRadius: '8px',
      backgroundColor: '#EAF7FF',
      px: 1.5,
      py: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
    title={label}
  >
    <Typography sx={{ fontSize: 14, fontWeight: 600 }} noWrap>
      {label}
    </Typography>
    {!!onDownload && (
      <IconButton onClick={onDownload} sx={{ p: 0, ml: 2 }}>
        <DownloadIcon />
      </IconButton>
    )}
    {!!onDelete && (
      <IconButton onClick={onDelete} sx={{ p: 0 }}>
        <CloseIcon />
      </IconButton>
    )}
  </Box>
);

FileChip.propTypes = {
  label: PropTypes.string,
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
};

export default FileChip;
