import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Files from 'react-files';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import PhoneInput from 'components/PhoneInput';
import { resizeFile, toBase64 } from 'utils/utils';
import { countries } from 'enum/constants';

import { updateCustomerInfo } from '../senario-actions';

import '../styles.scss';

const stringToAvatar = (org) => {
  if (org.logo) {
    return { src: org.logo };
  }
  return { children: <ApartmentIcon sx={{ fontSize: 72 }} /> };
};

const validationSchema = object().shape({
  companyName: string().trim().required('Company name is required'),
  address: string().trim().required('Street address is required'),
  country: string().trim().required('Country is required'),
  state: string().trim().required('State is required'),
  city: string().trim().required('City is required'),
  zipCode: string().trim().required('ZIP code is required'),
  contactName: string().trim().required('Name is required'),
});

const OrgProfile = ({ currentCustomer, updateCustomerInfo, onFormChanged }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleSave = useCallback(
    async (values, { setErrors }) => {
      if (!isValidPhoneNumber(values.contactName)) {
        setErrors({ contactName: 'Invalid phone number' });
      }
      try {
        const payload = {
          name: values.companyName,
          logo: values.logo,
          address: {
            city: values.city,
            country: values.country,
            line1: values.address,
            postal_code: values.zipCode,
            state: values.state,
          },
          contact: {
            name: values.contactName,
            phone: values.contactPhone,
          },
        };
        await updateCustomerInfo(payload);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    [updateCustomerInfo],
  );

  const { values, touched, errors, handleChange, handleSubmit, setFieldValue, isSubmitting, dirty } = useFormik({
    initialValues: {
      logo: currentCustomer.logo,
      companyName: currentCustomer.name,
      address: currentCustomer.address.line1,
      country: currentCustomer.address.country,
      state: currentCustomer.address.state,
      city: currentCustomer.address.city,
      zipCode: currentCustomer.address.postal_code,
      contactName: currentCustomer.contact?.name,
      contactPhone: currentCustomer.contact?.phone,
    },
    validationSchema,
    onSubmit: handleSave,
  });

  useEffect(() => {
    onFormChanged(dirty);
  }, [dirty, onFormChanged]);

  const handleAvatarChange = useCallback(
    async ([file]) => {
      if (file) {
        const format = file.type.split('/')[1];
        if (format !== 'png' && format !== 'jpg' && format !== 'jpeg' && format !== 'svg+xml') {
          enqueueSnackbar('Only png, jpg, jpeg, svg files are allowed', { variant: 'error' });
        }
        let image = null;
        if (format === 'svg+xml') image = await toBase64(file);
        else image = await resizeFile(file, 144, 144, format);
        setFieldValue('logo', image);
      }
    },
    [enqueueSnackbar, setFieldValue],
  );

  const handleRemoveAvatar = () => {
    setFieldValue('logo', '');
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 4 }}>
        <Grid item xs={12} sm={3}>
          <Stack spacing={2}>
            <Avatar sx={{ width: 144, height: 144, fontSize: 64 }} {...stringToAvatar(values)} data-testid="org-logo" />
            <Files
              multiple={false}
              accepts={['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml']}
              onChange={handleAvatarChange}
            >
              <Button
                size="large"
                variant="contained"
                startIcon={<UploadIcon />}
                sx={{ width: 144 }}
                data-testid="btn-avatar_upload"
              >
                Upload
              </Button>
            </Files>
            <Button
              size="large"
              color="error"
              variant="outlined"
              startIcon={<DeleteIcon />}
              sx={{ width: 144 }}
              onClick={handleRemoveAvatar}
              data-testid="btn-avatar_delete"
            >
              Delete
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputLabel>Company Name</InputLabel>
              <TextField
                fullWidth
                value={values.companyName}
                name="companyName"
                onChange={handleChange}
                helperText={!!touched.companyName && errors.companyName}
                error={Boolean(touched.companyName && errors.companyName)}
                FormHelperTextProps={{ sx: { ml: 0 } }}
                data-testid="input-companyName"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Street Address</InputLabel>
              <TextField
                fullWidth
                value={values.address}
                name="address"
                onChange={handleChange}
                helperText={!!touched.address && errors.address}
                error={Boolean(touched.address && errors.address)}
                FormHelperTextProps={{ sx: { ml: 0 } }}
                data-testid="input-address"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Country</InputLabel>
              <Select
                fullWidth
                name="country"
                value={values.country}
                onChange={handleChange}
                sx={{ fontSize: 16 }}
                data-testid="select-timeout"
              >
                {countries.map(({ code, label }) => (
                  <MenuItem value={code.toLowerCase()} key={`country-${code}`} sx={{ fontSize: 16 }}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>State</InputLabel>
              <TextField
                fullWidth
                value={values.state}
                name="state"
                onChange={handleChange}
                helperText={!!touched.state && errors.state}
                error={Boolean(touched.state && errors.state)}
                FormHelperTextProps={{ sx: { ml: 0 } }}
                data-testid="input-state"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>City</InputLabel>
              <TextField
                fullWidth
                value={values.city}
                name="city"
                onChange={handleChange}
                helperText={!!touched.city && errors.city}
                error={Boolean(touched.city && errors.city)}
                FormHelperTextProps={{ sx: { ml: 0 } }}
                data-testid="input-city"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>ZIP Code</InputLabel>
              <TextField
                fullWidth
                value={values.zipCode}
                name="zipCode"
                onChange={handleChange}
                helperText={!!touched.zipCode && errors.zipCode}
                error={Boolean(touched.zipCode && errors.zipCode)}
                FormHelperTextProps={{ sx: { ml: 0 } }}
                data-testid="input-zipCode"
              />
            </Grid>
          </Grid>
          <Typography mt={3} fontSize={24} sx={{ color: '#76888f', fontWeight: 'bold' }}>
            Administrative Contact
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <InputLabel>Name</InputLabel>
              <TextField
                fullWidth
                value={values.contactName}
                name="contactName"
                onChange={handleChange}
                helperText={!!touched.contactName && errors.contactName}
                error={Boolean(touched.contactName && errors.contactName)}
                FormHelperTextProps={{ sx: { ml: 0 } }}
                data-testid="input-contactName"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Phone</InputLabel>
              <PhoneInput
                value={values.contactPhone}
                name="contactPhone"
                onChange={(value) => setFieldValue('contactPhone', value)}
                helperText={!!touched.contactPhone && errors.contactPhone}
                error={Boolean(touched.contactPhone && errors.contactPhone)}
                FormHelperTextProps={{ sx: { ml: 0 } }}
                data-testid="input-contactPhone"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ width: '100%', my: 3 }} />
      <Stack direction="row" spacing={2} justifyContent="end">
        <Button
          size="large"
          variant="outlined"
          color="inherit"
          sx={{ width: 120 }}
          onClick={() => history.goBack()}
          data-testid="btn-cancel_update"
        >
          Cancel
        </Button>
        <Button
          size="large"
          variant="contained"
          sx={{ width: 120 }}
          onClick={handleSubmit}
          disabled={isSubmitting}
          startIcon={isSubmitting ? <CircularProgress size={12} /> : null}
          data-testid="btn-user_update"
        >
          Update
        </Button>
      </Stack>
    </>
  );
};

OrgProfile.propTypes = {
  currentCustomer: PropTypes.object,
  updateCustomerInfo: PropTypes.func,
  onFormChanged: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentCustomer: state.customers.currentCustomer,
});

const mapDispatchToProps = (dispatch) => ({
  updateCustomerInfo: (customer) => dispatch(updateCustomerInfo({ customer })),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgProfile);
