import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

const CloseDialog = ({ ticketId, open, onClose, onCloseTicket }) => (
  <>
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle sx={{ fontSize: 24, p: 4 }}>Is this ticket #{ticketId} resolved?</DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogActions sx={{ px: 4, py: 3, justifyContent: 'space-between' }}>
        <Button variant="contained" size="large" sx={{ fontSize: 14, width: 100 }} onClick={() => onCloseTicket(true)}>
          Yes
        </Button>
        <Button size="large" sx={{ fontSize: 14 }} onClick={() => onCloseTicket(false)}>
          No, but close anyway
        </Button>
      </DialogActions>
    </Dialog>
  </>
);

CloseDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  ticketId: PropTypes.number,
  onCloseTicket: PropTypes.func,
};

export default CloseDialog;
