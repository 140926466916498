import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';

import { getThreatIndex } from 'utils/utils';
import UsageChart from './UsageChart';
import BlockedChart from './BlockedChart';
import ThreatChart from './ThreatChart';

const ChartCard = ({ uuid, metrics }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { network: networkData, threat: threatData, blocked: blockedData, threatIndex } = metrics;

  const internal = threatIndex?.internal || 0;
  const external = threatIndex?.external || 0;
  const combined = internal * 0.7 + external * 0.3;

  return (
    <Grid container spacing={4} columns={18}>
      <Grid item xs={3}>
        <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
          <Typography
            fontSize={21}
            sx={{ color: '#4986c5', cursor: 'pointer', wordWrap: 'break-word' }}
            onClick={() => history.push(`${pathname}/${uuid}`)}
          >
            {metrics.name}
          </Typography>
          <Typography noWrap component="div" fontSize={12}>
            {uuid}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <UsageChart data={networkData} />
      </Grid>
      <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
        {blockedData.length ? (
          <BlockedChart data={blockedData} />
        ) : (
          <Typography fontSize={16} fontWeight="bold" data-testid="blocked-total">
            0
          </Typography>
        )}
      </Grid>
      <Grid item xs={3} display="flex" alignItems="center">
        <ThreatChart data={threatData} />
      </Grid>
      <Grid item xs={4} display="flex" alignItems="center">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="center">
              <Box width={6} height={44} sx={{ backgroundColor: getThreatIndex(internal).color, mr: 1 }} />
              <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  fontSize={20}
                  lineHeight="24px"
                  data-testid="threat-index-internal"
                >
                  {internal.toFixed(1)}
                </Typography>
                <Typography
                  fontWeight={500}
                  fontSize={12}
                  lineHeight="16px"
                  color="#A3ABB3"
                  data-testid="threat-index-internal-severity"
                >
                  {getThreatIndex(internal).label}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="center">
              <Box width={6} height={44} sx={{ backgroundColor: getThreatIndex(external).color, mr: 1 }} />
              <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  fontSize={20}
                  lineHeight="24px"
                  data-testid="threat-index-external"
                >
                  {external.toFixed(1)}
                </Typography>
                <Typography
                  fontWeight={500}
                  fontSize={12}
                  lineHeight="16px"
                  color="#A3ABB3"
                  data-testid="threat-index-external-severity"
                >
                  {getThreatIndex(external).label}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="center">
              <Box width={6} height={44} sx={{ backgroundColor: getThreatIndex(combined).color, mr: 1 }} />
              <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  fontSize={20}
                  lineHeight="24px"
                  data-testid="threat-index-combined"
                >
                  {combined.toFixed(1)}
                </Typography>
                <Typography
                  fontWeight={500}
                  fontSize={12}
                  lineHeight="16px"
                  color="#A3ABB3"
                  data-testid="threat-index-combined-severity"
                >
                  {getThreatIndex(combined).label}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ChartCard.propTypes = {
  uuid: PropTypes.string,
  metrics: PropTypes.object,
};

export default ChartCard;
