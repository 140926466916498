import React from 'react';
import { Box, Card, Divider, Stack, Typography, alpha } from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import StatusChip from '../TicketCard/StatusChip';

const StatusCard = ({ ticket }) => (
  <Card
    sx={{
      mt: { xs: 3, lg: 0 },
    }}
  >
    <Stack
      direction="row"
      divider={<Divider sx={{ background: alpha('#999', 0.1) }} orientation="vertical" flexItem />}
      justifyContent="space-around"
      alignItems="baseline"
      spacing={0}
    >
      <Box px={3} py={2} textAlign="center">
        <Typography fontSize={20} fontWeight="normal" color="text.primary" gutterBottom>
          Status
        </Typography>
        <StatusChip stateId={ticket?.state_id} />
      </Box>
      <Box px={3} py={2} textAlign="center">
        <Typography fontSize={20} fontWeight="normal" color="text.primary" gutterBottom>
          Last Update
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography color="text.secondary" sx={{ lineHeight: '25px' }}>
            {dayjs(ticket?.updated_at).format('YYYY-MM-DD HH:mm:ss')}
          </Typography>
        </Box>
      </Box>
    </Stack>
  </Card>
);

StatusCard.propTypes = {
  ticket: PropTypes.object,
};

export default StatusCard;
