/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Button } from 'react-bootstrap';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import Form from 'components/Form';

export function Footer({ edit, disabled, customButtonString = '', onClick, onDelete }) {
  return (
    <div className="survey__footer">
      {edit && (
        <Button bsStyle="danger" onClick={onDelete}>
          Delete
        </Button>
      )}
      <Button bsStyle="primary" className="right" disabled={disabled} onClick={onClick}>
        {customButtonString || (edit ? 'Save' : 'Add')}
      </Button>
    </div>
  );
}

export function GenericFooter({ edit, onClick, disabled, customButtonString = '', onDelete, onClose }) {
  return (
    <div className="nat__footer">
      <div className="rightActions">
        <Button onClick={onClose}>Cancel</Button>
        <Button bsStyle="primary" disabled={disabled} onClick={onClick}>
          {customButtonString || 'Save'}
        </Button>
      </div>
      {edit && (
        <Button bsStyle="danger" onClick={onDelete}>
          Delete
        </Button>
      )}
    </div>
  );
}

export function ConfirmFooter({ disabled = false, onYes, onNo }) {
  return (
    <div className="survey__footer">
      <Button bsStyle="primary" onClick={onNo}>
        No
      </Button>
      <Button bsStyle="danger" className="right" onClick={onYes} disabled={disabled}>
        Yes
      </Button>
    </div>
  );
}

export const isSelected = (value, arr) => arr.findIndex((ps) => ps.value === value) !== -1;

export const any = { value: 'any', label: 'any', type: 'any' };

/* eslint-disable-next-line react/display-name */
export const ApplicationsSelectComp = memo(({ value, onChange, options, selectedValues }) => (
  <Form.Select
    value={value}
    placeholder="Application"
    onChange={onChange}
    options={[
      { value: 'any', label: 'any' },
      ...options.reduce(
        (newArr, app) =>
          !isSelected(app.id, selectedValues)
            ? newArr.concat({
                value: app.id,
                label: app.name,
              })
            : newArr,
        [],
      ),
    ]}
  />
));

ApplicationsSelectComp.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  selectedValues: PropTypes.array.isRequired,
};

ApplicationsSelectComp.defaultProp = {
  options: [],
  value: '',
  selectedValues: [],
};

export const StepFooter = ({
  submit,
  disabled,
  customButtonString = '',
  onBack,
  onClick,
  forwardNav = true,
  backwardNav = true,
}) => (
  <div className="survey__footer">
    {backwardNav && (
      <Button bsStyle="primary" onClick={onBack}>
        Back
      </Button>
    )}
    {forwardNav && (
      <Button className="right" bsStyle="primary" disabled={disabled} onClick={onClick}>
        {customButtonString || (submit ? 'Save' : 'Next')}
      </Button>
    )}
  </div>
);

StepFooter.defaultProp = {
  forwardNav: true,
  backwardNav: true,
  submit: false,
  disabled: false,
  customButtonString: '',
};

StepFooter.propTypes = {
  disabled: PropTypes.bool,
  forwardNav: PropTypes.bool,
  backwardNav: PropTypes.bool,
  submit: PropTypes.bool,
  customButtonString: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onBack: PropTypes.func,
};

export const UsersTable = ({ users, removeUser, children }) => (
  <div className="local-users--container">
    <table>
      <tbody>
        <tr className="entries tHeader">
          <th className="fName">First Name</th>
          <th className="lName">Last Name</th>
          <th className="role">Role</th>
          <th className="email">Email</th>
          <th className="pwd">Password</th>
        </tr>
        {users.map((user, index) => (
          <tr key={`additional-user-index-${index}`} className="entries">
            <td className="fName">{user.firstName}</td>
            <td className="lName">{user.lastName}</td>
            <td className="role">{user.role.label || ''}</td>
            <td className="email">{user.email}</td>
            <td className="pwd">*******</td>
            <td className="last">
              <IconButton color="error" size="small" onClick={() => removeUser(index)}>
                <DeleteIcon />
              </IconButton>
            </td>
          </tr>
        ))}
        {children}
      </tbody>
    </table>
  </div>
);

UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
  removeUser: PropTypes.func.isRequired,
  children: PropTypes.node,
};

UsersTable.defaultProp = {
  users: [],
};

export const UsersInvitationTable = ({ users }) => (
  <div className="local-users--container">
    <table>
      <thead>
        <tr className="entries tHeader">
          <th className="fName">First Name</th>
          <th className="lName">Last Name</th>
          <th className="email">Email</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => (
          <tr key={`additional-user-index-${index}`} className="entries">
            <td className="fName">{user.firstName}</td>
            <td className="lName">{user.lastName}</td>
            <td className="email">{user.email}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);
UsersInvitationTable.propTypes = {
  users: PropTypes.array.isRequired,
};

UsersTable.defaultProp = {
  users: [],
};
Footer.defaultProp = {
  edit: false,
  disabled: false,
  customButtonString: '',
};

Footer.propTypes = {
  disabled: PropTypes.bool,
  edit: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  customButtonString: PropTypes.string,
};

GenericFooter.defaultProp = {
  edit: false,
  disabled: false,
  customButtonString: '',
};

GenericFooter.propTypes = {
  disabled: PropTypes.bool,
  edit: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  customButtonString: PropTypes.string,
};

ConfirmFooter.defaultProp = {
  disabled: false,
};
ConfirmFooter.propTypes = {
  disabled: PropTypes.bool,
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
};
